import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../components/header';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';

export default function Thankyou() {
    const navigate = useNavigate();
    setTimeout(() => {
        navigate('/home');
    }, 15000);
  return (
    <div>
      <AppHeader />
      <div className='container-fluid py-5' style={{backgroundColor:'#22222a'}}>
        <div className='container py-5'>
          <div className='row py-5 d-flex align-items-center justify-content-center text-center'>
            <div className='col-lg-6'>
                <img src='/assets/images/thankyou.webp' style={{width:'200px'}} />
                <h1 className='text-white mb-3 display-1'>Thank You!</h1>
                <p className='text-white lh-lg'>Your mail has been successfully sent. We will get back to you soon.</p>
                <Link className='btn btn-primary'>Home Page</Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React from 'react';
import AppHeader from '../components/header';
import Footer from '../components/footer';

export default function AboutUsPage() {
  return (
    <div>
      <AppHeader />
      <div className="container-fluid hero_banner d-flex align-items-start justify-content-center" style={{backgroundImage:'url(./assets/images/about-banner.webp)'}}>
        <div className='container'>
          <div className="row d-flex align-items-center justify-content-center mt-5">
              <div className="col-lg-6">
                  <h1 className="p-3 fw-normal text-center text-white mt-4 mb-0">
                      About Us 
                  </h1>
                  <p className="text-center text-white fs-6" style={{ marginTop: '-10px' }}>
                  Welcome to VITTI Living, where your vision for a beautifully furnished home comes to life. We specialize in crafting customized and premium quality home furniture that perfectly fits your unique style and needs.
                  </p>
              </div>
          </div>
        </div>
      </div>

      <div className='container-fluid py-5' style={{backgroundColor:'#33333a'}}>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-lg-6'>
              <img src='/assets/images/about-img.webp' className='w-100' />
            </div>
            <div className='col-lg-6'>
              <h2 className='text-white mb-3'>About VITTI Living</h2>
              <p className='text-white lh-lg'>Welcome to VITTI Living, your premier destination for customized and premium quality home furniture. Our extensive manufacturing unit, equipped with the latest technology and operated by skilled professionals, allows us to produce a wide range of furniture pieces that cater to your unique style and preferences. Whether you're looking for a modern aesthetic or classic elegance, our dedicated team of designers and craftsmen are committed to creating furniture that not only enhances your living space but also stands the test of time.</p>
              <p className='text-white lh-lg'>At VITTI Living, we believe that your home should be a true reflection of your personality. That's why we offer bespoke furniture solutions that are tailored to meet your specific needs. Our meticulous attention to detail, from the selection of the finest materials to the final finishing touches, ensures that every piece we create is of the highest quality. Experience the VITTI Living difference and transform your home with furniture that is as unique as you are.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

import React from "react";

export default function Error() {
    return (
        <div className="container-fluid vh-100 d-flex align-items-center justify-content-center text-center bg-dark text-white">
            <div className="row">
                <div className="col">
                    <h1 className="fw-bold">404 Page Error</h1>
                    <h4>Sorry, This page doesn't exist</h4>
                </div>
            </div>
        </div>
    )
}
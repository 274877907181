import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/home';
import AboutUsPage from './pages/aboutus';
import ContactusPage from './pages/contactus';
import FaqsPage from './pages/faqs';
import ProductsPage from './pages/products';
import ProductView from './components/product_view';
import Error from './components/error';
import PrivacyPolicyPage from './pages/privacy_policy';
import CareersPage from './pages/careers';
import Thankyou from './pages/thank-you';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Homepage />} />
          <Route path='/home' element={<Homepage />} />
          <Route path='/aboutus' element={<AboutUsPage />} />
          <Route path='/contactus' element={<ContactusPage />} />
          <Route path='/careers' element={<CareersPage />} />
          <Route path='/faqs' element={<FaqsPage />} />
          <Route path='/privacy_policy' element={<PrivacyPolicyPage />} />
          <Route path='/products/:id' element={<ProductsPage />} />
          <Route path='/products' element={<ProductsPage />} />
          <Route path='/product_view/:id' element={<ProductView />} />
          <Route path='/thank-you' element={<Thankyou />} />
          <Route path='./' element={<Homepage />} />
          <Route path='*' element={<Error />} />
          <Route element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

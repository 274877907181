import React from 'react';
import AppHeader from '../components/header';
import Footer from '../components/footer';

export default function CareersPage() {
  return (
    <div>
      <AppHeader />
      <div className="container-fluid hero_banner d-flex align-items-start justify-content-center" style={{backgroundImage:'url(./assets/images/about-banner.webp)'}}>
        <div className='container'>
          <div className="row d-flex align-items-center justify-content-center mt-5">
              <div className="col-lg-6">
                  <h1 className="p-3 fw-normal text-center text-white mt-4 mb-0">
                      VITTI Careers
                  </h1>
                  <p className="text-center text-white fs-6" style={{ marginTop: '-10px' }}>
                  Join our innovative team where craftsmanship meets creativity. Whether you're skilled in manufacturing, design, or business, you'll find a place to grow and thrive with us. Shape the future of living spaces with VITTI Living.
                  </p>
              </div>
          </div>
        </div>
      </div>

      <div className='container-fluid py-5' style={{backgroundColor:'#33333a'}}>
        <div className='container py-5'>
          <div className='row d-flex align-items-center justify-content-center'>
            <div className='col-lg-6 py-5'>
              <h2 className='text-white mb-2 text-center'>Currently No Hirings</h2>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { getCategories, getProductsByCategory } from "../utils/api";
import Popup_Enq_Form from './popup_enq_form';

export default function AppHeader() {
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchCategories();
        fetchSearchProducts();
    }, []);

    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await getCategories();
            if (response.status === 200) {
                setCategories(response.data.categories);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const fetchSearchProducts = async () => {
        try {
            setLoading(true);
            const response = await getProductsByCategory();
            if (response.status === 200) {
                setProducts(response.data.category);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } catch (e) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (term.length === 0) {
            setSearchResults([]);
            return;
        }

        const results = [];
        products.forEach(category => {
            if (category.name.toLowerCase().includes(term.toLowerCase())) {
                results.push({ id: category.id, name: category.name, type: 'category' });
            }

            category.sub_categories.forEach(subCategory => {
                if (subCategory.name.toLowerCase().includes(term.toLowerCase())) {
                    results.push({ id: subCategory.id, name: subCategory.name, type: 'sub-category' });
                }

                subCategory.products.forEach(product => {
                    if (product.name.toLowerCase().includes(term.toLowerCase())) {
                        results.push({ id: product.id, name: product.name, type: 'product' });
                    }
                });
            });
        });

        setSearchResults(results);
        // setIsSearchVisible(false);
    };

    const handleSelect = (result) => {
        let path = '';
        if (result.type === 'category') {
            path = `/products/${result.id}?name=${encodeURIComponent(result.name)}`;
        } else if (result.type === 'sub-category') {
            path = `/products/${result.id}?name=${encodeURIComponent(result.name)}`;
        } else if (result.type === 'product') {
            path = `/product_view/${result.id}`;
        }
        navigate(path);
    };

    const handleMouseEnter = (category) => {
        setActiveCategory(category);
    };

    const handleMouseLeave = () => {
        setActiveCategory(null);
    };
    
    const [loading, setLoading] = useState(false);
    if (loading) {
        return <div className="bg-dark text-white d-flex flex-column align-items-center justify-content-center vh-100 loader-preview">
            <img src={`/assets/images/vitti_logo_white.png`} className="w-25 mb-5" />
            <h4>Loading...</h4>
            </div>;
    };

    return (
        <div>
        <Navbar expand="lg" className="main-nav bg-white" sticky="top">
            <Container fluid className='my-container'>
                <Navbar.Brand href="/">
                    <img src="/assets/images/vitti_logo.png" className="vitti_logo" alt="React Bootstrap logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="m-auto my-2 my-lg-0" style={{ maxHeight: '300px' }} navbarScroll >
                        <Link to='/products' className='main_menu_link'>ALL</Link>
                        {categories.map(category => (
                            <NavLink 
                                key={category.id}
                                className='main_menu_link'
                                activeClassName='active'
                                exact to={`/products/${category.id}?name=${encodeURIComponent(category.name)}`}
                                onMouseEnter={() => handleMouseEnter(category)} 
                                onMouseLeave={handleMouseLeave}
                            >
                                {category.name.toUpperCase()}
                            </NavLink>
                        ))}
                    </Nav>
                    {activeCategory && (
                        <div className="dropdown-menu my-dropdown-menu show" onMouseEnter={() => handleMouseEnter(activeCategory)} onMouseLeave={handleMouseLeave}>
                            <div className='container-fluid'>
                                <div className="row">
                                    <div className='col-lg-4 px-5 py-4'>
                                        <Link to={`/products/${activeCategory.id}?name=${encodeURIComponent(activeCategory.name)}`} onClick={() => setActiveCategory(null)}>
                                            <img src={`http://vittiliving.com/${activeCategory.image}`} className='w-100 mb-1' alt='vitti category image' />
                                            <p>{activeCategory.description}</p>
                                        </Link>
                                    </div>
                                    <div className='col-lg-8 py-4'>
                                        <div className='row'>
                                            {activeCategory.sub_categories.map(subCategory => (
                                                <div key={subCategory.id} className='col-lg-3'>
                                                    <Link to={`/products/${subCategory.id}?name=${encodeURIComponent(subCategory.name)}`} onClick={() => setActiveCategory(null)}>
                                                        <img src={`http://vittiliving.com/${subCategory.image}`} className='w-100 mb-1' alt='vitti sub-category image' />
                                                        <p>{subCategory.name}</p>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Navbar.Collapse>
                <div className="d-flex vitti_header_right_icons">
                    {isSearchVisible && (
                        <div>
                            <div className='product-search-drop'>
                                <input
                                    type="text"
                                    name="search"
                                    className="form-control"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    placeholder="Search..."
                                />
                            </div>
                                {searchResults.length > 0 && (
                                    <ul className="list-group">
                                        {searchResults.map((result) => (
                                            <li
                                                key={result.id}
                                                className="list-group-item list-group-item-action"
                                                onClick={() => handleSelect(result)}
                                            >
                                                {result.name}
                                                {/* ({result.type}) */}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                        </div>
                    )}
                    <span className="vitti_icon1 prod-search-view" onClick={toggleSearch}><i className="fa-solid fa-magnifying-glass"></i></span>
                    <span className="vitti_icon2"><i className="fa-solid fa-user"></i></span>
                    <span className="vitti_icon3"><i className="fa-regular fa-heart"></i></span>
                    <span className="vitti_icon4"><i className="fa-solid fa-cart-shopping"></i></span>
                </div>
            </Container>
        </Navbar>
        
        <a onClick={() => setShowModal(true)} target="_blank" className="whatsapp-widget vitti-chat d-block"><i class="fa-solid fa-comment-dots"></i></a>
        {showModal && <Popup_Enq_Form onClose={() => setShowModal(false)} />}
        <a href="https://web.whatsapp.com/send?phone=919090494957&amp;text=I'm%20interested%20to%20know%20about%20your%20products" target="_blank" className="whatsapp-widget d-lg-block d-md-block d-sm-none d-none"><i class="fab fa-whatsapp"></i></a>
        <a href="whatsapp://send?phone=919090494957&amp;text=I'm%20interested%20to%20know%20about%20your%20products" target="_blank" className="whatsapp-widget d-block d-sm-block d-md-none d-lg-none"><i class="fab fa-whatsapp"></i></a>
        </div>
    );
}

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation, NavLink } from "react-router-dom";
import AppHeader from "../components/header";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { getProductsByCategory } from "../utils/api";

const Loader = () => {
    return <div className="bg-dark text-white d-flex flex-column align-items-center justify-content-center vh-100 loader-preview">
        <img src={`/assets/images/vitti_logo_white.png`} className="w-25 mb-5" />
        <h4>Loading...</h4>
        </div>;
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export default function ProductsPage() {
    const { id } = useParams();
    const query = useQuery();
    const name = query.get('name');
    const [loading, setLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    
    const productListRef = useRef(null);

    useEffect(() => {
        fetchInitialData();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [id, name, allProducts, categories]);

    const fetchInitialData = async () => {
        setLoading(true);
        await fetchCategoriesAndProducts();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const fetchCategoriesAndProducts = async () => {
        setLoading(true);
        try {
            const response = await getProductsByCategory();
            if (response.status === 200) {
                const categories = response.data.category;
                setCategories(categories);

                const allProducts = categories.flatMap(category => 
                    category.sub_categories.flatMap(subCategory => subCategory.products)
                );
                setAllProducts(allProducts);
            } else {
                console.error('Failed to fetch products:', response);
            }
        } catch (e) {
            console.error('Error fetching products:', e);
            setAllProducts([]);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const filterProducts = () => {
        setLoading(true);
        let filtered = allProducts;

        if (id && name) {
            const category = categories.find(category => category.id === Number(id) && category.name === name);
            if (category) {
                filtered = category.sub_categories.flatMap(subCategory => subCategory.products);
                setSelectedCategory(category);
                setSelectedSubCategory(null);
            } else {
                const subCategory = categories.flatMap(cat => cat.sub_categories).find(sub => sub.id === Number(id) && sub.name === name);
                console.log('Selected Sub-category:', subCategory);  // Log selected sub-category
                if (subCategory) {
                    filtered = subCategory.products;
                    const parentCategory = categories.find(cat => cat.sub_categories.some(sub => sub.id === Number(id) && sub.name === name));
                    setSelectedCategory(parentCategory);
                    setSelectedSubCategory(subCategory);
                }
            }
        } else {
            setSelectedCategory(null);
            setSelectedSubCategory(null);
        }
        setFilteredProducts(filtered);
        const productsListingDiv = document.getElementById('products-listing');
        if (productsListingDiv) {
            productsListingDiv.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };
    if (loading) {
        return <div className="bg-dark text-white d-flex flex-column align-items-center justify-content-center vh-100 loader-preview">
            <img src={`/assets/images/vitti_logo_white.png`} className="w-25 mb-5" />
            <h4>Loading...</h4>
            </div>;
    };

    return (
        <div>
            <AppHeader />
            <div className="container-fluid living_hero_banner d-flex align-items-start justify-content-center" style={{ backgroundImage: `url(https://vittiliving.com${(selectedCategory ? selectedCategory.banner_image : '/media/categories/living-sofas-banner2.webp')})` }}>
                <div className="container">
                    <div className="row d-flex align-items-center justify-content-center mt-5">
                        <div className="col-lg-6">
                            <h1 className="p-3 fw-normal text-center text-white mt-4 mb-0">
                                {selectedCategory ? selectedCategory.name.toUpperCase() : "All"}
                            </h1>
                            <p className="text-center text-white fs-6" style={{ marginTop: '-10px' }}>
                                {selectedCategory ? selectedCategory.banner_description : "Products"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pb-5">
                <div className="row d-flex align-items-center justify-content-center text-center">
                    <div className="col-12">
                        <h1 className="mt-5">
                            {selectedSubCategory ? selectedSubCategory.name.toUpperCase() : (selectedCategory ? selectedCategory.name.toUpperCase() : "All Products")}
                        </h1>
                        <div>
                            <span>{selectedCategory ? selectedCategory.name.toUpperCase() : "Products list"}</span>
                            {selectedSubCategory && <span>&#x2f;</span>}
                            <span>{selectedSubCategory ? selectedSubCategory.name : ""}</span>
                        </div>
                    </div>
                </div>
                <div className="row mt-5" id="products-listing" ref={productListRef}>
                    {loading ? (
                        <Loader />
                    ) : (
                        filteredProducts.length > 0 ? (
                            filteredProducts.map((product) => (
                                <div className="col-lg-4 col-sm-6 col-6" key={product.id}>
                                    <div className="vitti_prod_card">
                                        <Link to={`/product_view/${product.id}`}>
                                            <div className="vitti_prod_card_img">
                                                <img src={`https://vittiliving.com${product.image}`} className="w-100 prod-list-img" alt="VITTI Product Image" />
                                                {/* <img src={`/assets/images/living-products/sofas/Alea/ColorOption/color-1.webp`} className="w-100 prod-list-img-hover" alt="VITTI Product Image" /> */}
                                            </div>
                                            <div className="vitti_prod_card_title">
                                                <p>{product.name}</p>
                                                {/* <p>{`Price: ₹${product.price}`}</p> */}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center py-5">No Products Available</p>
                        )
                    )}
                </div>
            </div>
            <Navbar expand="lg" bg='white' className="footer_navbar p-0" fixed="bottom">
                <Container fluid>
                    <Navbar.Brand className='nav_title me-5'>{selectedCategory ? selectedCategory.name.toUpperCase() : "All Products"}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto my-2 my-lg-0">
                            {selectedCategory && selectedCategory.sub_categories.length > 0 ? (
                                selectedCategory.sub_categories.map((subCategory) => (
                                    <NavLink key={subCategory.id} activeClassName='active'
                                    exact to={`/products/${subCategory.id}?name=${encodeURIComponent(subCategory.name)}`} className="nav_link">
                                        {subCategory.name}
                                    </NavLink>
                                ))
                            ) : (
                                categories.map((category) => (
                                    <NavLink 
                                        key={category.id} 
                                        activeClassName='active'
                                        exact 
                                        to={`/products/${category.id}?name=${encodeURIComponent(category.name)}`} 
                                        className="nav_link"
                                    >
                                        {category.name}
                                    </NavLink>
                                ))
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import AppHeader from "./header";
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Accordion } from "react-bootstrap";
import Popup_Enq_Form from "./popup_enq_form";
import { getProductById, getProductsByCategory } from "../utils/api";

export default function ProductView() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedFabric, setSelectedFabric] = useState(null);
    const [isVisible, setIsVisible] = useState({});
    const [shouldRender, setShouldRender] = useState({});
    const contentRefs = useRef({});
    const [mainImage, setMainImage] = useState(product.image);
    const [randomProducts, setRandomProducts] = useState([]);

    useEffect(() => {
        fetchProducts();
    }, []);
    useEffect(() => {
        setMainImage(product.image);
    }, [product]);

    const handleThumbnailClick = (newImage) => {
        setMainImage(newImage);
    };

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const response = await getProductsByCategory();
            console.log("API Response:", response);
    
            // Extract categories from the response
            const categories = response?.data?.category || [];
            if (categories.length === 0) {
                console.warn("No categories found in response.");
                setRandomProducts([]);
                return;
            }
    
            // Gather all products
            const allProducts = [];
            categories.forEach(category => {
                category.sub_categories?.forEach(subCategory => {
                    subCategory.products?.forEach(product => {
                        allProducts.push(product);
                    });
                });
            });
    
            if (allProducts.length > 0) {
                const selectedProducts = getRandomProducts(allProducts, 16);
                setRandomProducts(selectedProducts);
            } else {
                console.warn("No products found in allProducts.");
                setRandomProducts([]);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } catch (e) {
            console.error("Error fetching products:", e);
            setRandomProducts([]);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };    

    const getRandomProducts = (productList, numberOfProducts) => {
        console.log("Product List for Random Selection:", productList); // Log input list
    
        if (!Array.isArray(productList) || productList.length === 0) {
            console.warn("Product list is empty or not an array.");
            return [];
        }
    
        const shuffled = productList.sort(() => 0.5 - Math.random());
        console.log("Shuffled Products:", shuffled); // Log shuffled products
    
        const selected = shuffled.slice(0, numberOfProducts);
        console.log("Selected Products:", selected); // Log selected products
    
        return selected;
    };

    useEffect(() => {
        console.log("Random Products:", randomProducts); // Debug logging
    }, [randomProducts]);

    const resetAllFabrics = () => {
        setIsVisible({});
        setShouldRender({});
    };

    const toggleFabric = (fabric) => {
        if (selectedFabric === fabric) {
            setIsVisible((prev) => ({ ...prev, [fabric]: false }));
            setTimeout(() => {
                setShouldRender((prev) => ({ ...prev, [fabric]: false }));
                setSelectedFabric(null);
            }, 500);
        } else {
            resetAllFabrics();
            setShouldRender((prev) => ({ ...prev, [fabric]: true }));
            setSelectedFabric(fabric);
            setTimeout(() => {
                setIsVisible((prev) => ({ ...prev, [fabric]: true }));
            }, 500);
        }
    };

    useEffect(() => {
        Object.keys(contentRefs.current).forEach((fabric) => {
            const element = contentRefs.current[fabric];
            if (element) {
                element.style.maxHeight = isVisible[fabric] ? `${element.scrollHeight}px` : "0px";
            }
        });
    }, [isVisible]);

    useEffect(() => {
        getProduct();
    }, [id]); 

    const getProduct = async () => {
        setLoading(true);
        try {
            setLoading(true);
            const response = await getProductById(id);

            if (response.status === 200) {
                setProduct(response.data.products || {});
            } else {
                setProduct({});
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } catch (e) {
            setProduct({});
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    if (loading) {
        return <div className="bg-dark text-white d-flex flex-column align-items-center justify-content-center vh-100 loader-preview">
            <img src={`/assets/images/vitti_logo_white.png`} className="w-25 mb-5" />
            <h4>Loading...</h4>
            </div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }
    const getFileType = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension) ? 'image' : 'video';
    };

    const previewUrl = `http://vittiliving.com${product.product_details && product.product_details.preview_image}`;
    const fileType = getFileType(previewUrl);

    const chunkArray = (arr, size) => {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    };

    // Chunk products into groups of 4
    const chunks = chunkArray(randomProducts, 4);

    return (
        <div>
            <AppHeader />
            <div className="container-fluid my-container py-4">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="prod-preview" style={{ overflow: 'hidden' }}>
                            <img 
                                src={`http://vittiliving.com${mainImage}`}
                                className="w-100" 
                                alt="vitti product" 
                                // style={{ transition: 'transform 0.5s' }}
                                // onMouseOver={(e) => e.target.style.transform = 'scale(1.3)'}
                                // onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="vitti_prod_view_details">
                            <div className="prod_view_title mb-2">
                                <h1><strong>{product.name}</strong></h1>
                            </div>
                            <div className="prod_view_price">
                            {product.price ? (
                            <>
                                <p className="mb-0">Starting from</p>
                                <h2><i className="fa-solid fa-indian-rupee-sign"></i> {product.price.toLocaleString('en-IN')}</h2>
                                <p>(Price excluding all taxes)</p>
                            </>
                            ): null}
                            </div>
                            <div className="prod_view_thumbs mb-4">
                                {
                                    product.product_colors && product.product_colors.some(color => color.image) && (
                                        <p>Choose Color</p>
                                    )
                                }
                                <div className="prod_thumbs">
                                    {product.product_colors && product.product_colors.map((color, index) => (
                                        color.image && (
                                            <img 
                                                key={index}
                                                onClick={() => handleThumbnailClick(color.image)}
                                                src={`http://vittiliving.com${color.image}`} 
                                                width={80} 
                                                className="me-2 mb-2" 
                                                alt="vitti product" 
                                            />
                                        )
                                    ))}
                                </div>
                            </div>
                            <div className="prod_view_cart_btn mb-2">
                                <Button onClick={() => setShowModal(true)} className="cart_btn">ENQUIRE NOW</Button>
                                {showModal && <Popup_Enq_Form onClose={() => setShowModal(false)} />}
                            </div>
                            <div className="prod_view_fav_btn text-center">
                                <Button className="fav_btn"><i className="fa-regular fa-heart"></i> ADD TO FAVORITE</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid my-container py-5" id="Product-Details">
                <div className="row">
                    <div className="sec_heading">
                        <h2>Product Details</h2>
                    </div>
                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                        <div className="vitti_prod_sec_content">
                            <p>{product.product_details && product.product_details.first_section_description}</p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        {product.product_details && product.product_details.first_section_image && (
                            <img 
                                src={`http://vittiliving.com${product.product_details.first_section_image}`} 
                                className="w-100" 
                                alt="VITTI Product image" 
                            />
                        )}
                    </div>
                </div>
                <div className="row py-5 align-items-center justify-content-center">
                    <div className="col-lg-10">
                        <a style={{ position: 'relative' }}>
                            {fileType === 'image' ? (
                                <img src={previewUrl} className="w-100" alt="VITTI Product image" />
                            ) : (
                                <video className="w-100" controls autoPlay>
                                    <source src={previewUrl} type={`video/${previewUrl.split('.').pop()}`} />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        {product.product_details && product.product_details.second_section_image && (
                            <img 
                                src={`http://vittiliving.com${product.product_details.second_section_image}`} 
                                className="w-100" 
                                alt="VITTI Product image" 
                            />
                        )}
                    </div>
                    <div className="col-lg-5 d-flex align-items-center justify-content-start">
                        <div className="vitti_prod_sec_content">
                            <p>{product.product_details && product.product_details.second_section_description}</p>
                        </div>
                    </div>
                </div>
                <div className="row py-5">
                    {product.product_images && product.product_images[0] && (
                        <div className="col-lg-4 col-sm-6 col-12">
                            <div className="vitti_prod_card mb-0">
                                <a href="#">
                                    <div className="vitti_prod_card_img">
                                        <img 
                                            src={`http://vittiliving.com${product.product_images[0].image}`} 
                                            className="w-100" 
                                            alt="vitti product image" 
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                    )}
                    {product.product_images && product.product_images[1] && (
                        <div className="col-lg-4 col-sm-6 col-12">
                            <div className="vitti_prod_card mb-0">
                                <a href="#">
                                    <div className="vitti_prod_card_img">
                                        <img 
                                            src={`http://vittiliving.com${product.product_images[1].image}`} 
                                            className="w-100" 
                                            alt="vitti product image" 
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                    )}
                    {product.product_images && product.product_images[2] && (
                        <div className="col-lg-4 col-sm-6 col-12">
                            <div className="vitti_prod_card mb-0">
                                <a href="#">
                                    <div className="vitti_prod_card_img">
                                        <img 
                                            src={`http://vittiliving.com${product.product_images[2].image}`} 
                                            className="w-100" 
                                            alt="vitti product image" 
                                        />
                                    </div>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className="container-fluid my-container my-container-md py-4" id="Finishes">
                {product.finishes && product.finishes.length > 0 && (
                    <div className="sec_heading">
                        <h2>Finishes</h2>
                    </div>
                )}
                
                <div className="row">
                    {product.finishes && product.finishes.map((finish, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                            <div className="col-12 d-flex flex-wrap justify-content-start mb-4">
                                {product.finishes.slice(rowIndex * 6, rowIndex * 6 + 6).map((finish, index) => (
                                    <div key={finish.id} className={`vitti-prod-fabric-box col-lg-2 col-md-4 col-sm-6 ${selectedFabric === finish.id ? 'selected' : ''}`}>
                                        <img src={`http://vittiliving.com${finish.image}`} className="w-100" alt={finish.name} />
                                        <div className="vitti-prod-fabric-box-text">
                                            <p>{finish.name}</p>
                                            <a className="select-fab-btn" onClick={() => toggleFabric(finish.id)}>
                                                <i className={`fa-solid ${isVisible[finish.id] ? 'fa-minus' : 'fa-plus'}`}></i>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="col-12">
                                {product.finishes.slice(rowIndex * 6, rowIndex * 6 + 6).map((finish) => (
                                    shouldRender[finish.id] && (
                                        <div key={finish.id} className={`fabric-details-box ${isVisible[finish.id] ? 'show' : ''}`}>
                                            <div className="row">
                                                {finish.options.map((option, index) => (
                                                    <div key={index} className="col-lg-2 col-md-4 col-sm-6 vitti-prod-fabric-box">
                                                        <img src={`http://vittiliving.com${option.image}`} className="w-100" alt={option.name} />
                                                        <div className="vitti-prod-fabric-box-text">
                                                            <p>{option.name}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>

            <div className="container-fluid my-container my-container-md py-4" id="Measurements">
                {product.measurements && product.measurements.filter(measurement => measurement.images && measurement.images.length > 0).length > 0 && (
                    <div className="sec_heading">
                        <h2>Measurements</h2>
                    </div>
                )}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="vitti_prod_measure_accord">
                            <Accordion>
                                {product.measurements && product.measurements
                                    .filter(measurement => measurement.images && measurement.images.length > 0)
                                    .map((measurement, index) => (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header>{measurement.tab_name}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="row">
                                                    {measurement.images.map((img, imgIndex) => (
                                                        <div key={imgIndex} className="col-lg-3 col-sm-6 col-12">
                                                            <div className="vitti_prod_measure_img">
                                                                <img 
                                                                    src={`http://vittiliving.com${img.image}`} 
                                                                    className="w-100" 
                                                                    alt={`${measurement.tab_name} image`} 
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            
            {chunks.length > 0 && (
                <div className="container-fluid my-container py-5 random-product-slide">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sec_heading">
                                <h2>Similar Products</h2>
                            </div>
                            <Carousel>
                                {chunks.map((chunk, index) => (
                                    <Carousel.Item key={index}>
                                        <div className="d-flex">
                                            {chunk.map((prod) => (
                                                <div key={prod.id} className="vitti_prod_card m-2" style={{ width: '25%' }}>
                                                    <Link onClick={() => window.location.href = `/product_view/${prod.id}`}>
                                                        <div className="vitti_prod_card_img">
                                                            <img src={`https://vittiliving.com${prod.image}`} className="w-100" alt="VITTI Product Image" />
                                                        </div>
                                                        <div className="vitti_prod_card_title">
                                                            <p>{prod.name}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            )}

            
            <Navbar bg='white' className="footer_navbar p-0" fixed="bottom">
                <Container fluid>
                    <Navbar id="navbarScroll">
                        <Nav className="me-auto my-lg-0" style={{ Height: '40px' }} navbarScroll >
                            <Nav.Link className='nav_link' href="#Product-Details">Product Details</Nav.Link>
                            <Nav.Link className='nav_link' href="#Finishes">Finishes</Nav.Link>
                            <Nav.Link className='nav_link' href="#Measurements">Measurements</Nav.Link>
                        </Nav>
                    </Navbar>
                </Container>
            </Navbar>
        </div>
    );
}

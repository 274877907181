import http from "./http";

export const getCategories = (payload = {}) => {
    // const mytoken = sessionStorage.getItem('token');
    const headers = {
        // 'Authorization': 'Bearer',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Expose-Headers': 'x-xsrf-token',
    };
    return http.get('/categories');
};
export const getProductsByCategory = ( payload = {}) => {
    const headers = {
        // Add necessary headers if needed
    };
    return http.get(`/all-products`, { headers });
};
export const getProductsBySubCategory = (categoryId, payload = {}) => {
    const headers = {
        //
    };
    return http.get(`/products?sub_category_id=${categoryId}`, { headers });
};
export const getProductById = (productId, payload = {}) => {
    const headers = {
        //
    };
    return http.get(`/product?product_id=${productId}`, { headers });
};
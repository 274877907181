import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppHeader from "../components/header";
import Footer from "../components/footer";
import Carousel from 'react-bootstrap/Carousel';

export default function Homepage() {
    return(
        <div className="wrapper">
            <AppHeader />
            <div className="container-fluid pb-3">
                <div className="home_hero_banner d-flex align-items-end justify-content-center">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="p-3">Thoughtful Design. Mindful Living.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5">
                <div className="row">
                    <div className="col-lg-7">
                        <img src="/assets/images/home-images/sec-2-bg.webp" className="w-100" alt="VITTI Product image" />
                    </div>
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                        <div className="vitti_sec2 text-center">
                            <h2 className="mb-4">Night System</h2>
                            {/* <p>The wardrobe is probably the most personal and private piece of furniture in the home – functional yet mysterious and secret.</p> */}
                            <Link to={`/products/8?name=${encodeURIComponent('Wardrobe')}`} className="sec2_btn mb-3">DISCOVER</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5">
                <div className="row">
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                        <div className="vitti_sec2 text-center">
                            <h2 className="mb-4">Kitchen</h2>
                            {/* <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p> */}
                            <Link to={`/products/7?name=${encodeURIComponent('Kitchen')}`} className="sec2_btn mb-3">DISCOVER</Link>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <img src="/assets/images/home-images/sec-3-bg.webp" className="w-100" alt="VITTI Product image" />
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid py-5">
                <div className="row">
                    <div className="sec_heading">
                        <h2>New Arrivals</h2>
                    </div>
                    <div className="col-lg-12">
                        <Carousel data-bs-theme="dark" indicators={false} touch={true}>
                            <Carousel.Item>
                                <div className="row">
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-1.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-2.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-3.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row">
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-2.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-3.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-1.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row">
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-3.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-1.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                    <div className="col-lg-4 vitti_home_card_slider1">
                                        <img className="d-block w-100" src="/assets/images/home-images/sec-4-card-2.webp" alt="Second slide" />
                                        <h4>Collection Tittle</h4>
                                        <p>The Molteni&C Kitchen Collection offers a broad array of highly customizable solutions engineered by Dada Engeneered.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div> */}
            <div class="container-fluid py-5">
                <div class="row px-3 vitti_home_gallery_sec">
                    <div class="col-lg-4 col-sm-2 col-6 p-0">
                        <Link to={`/products/1?name=${encodeURIComponent('Sofas')}`} class="h-100">
                            <img class="img-fluid w-100" src="/assets/images/home-images/sec-5-gallery-1.webp" alt="" />
                            <h4>Sofas</h4>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-sm-2 col-6 p-0">
                        <Link to={`/products/2?name=${encodeURIComponent('Beds')}`} class="h-100">
                            <img class="img-fluid w-100" src="/assets/images/home-images/sec-5-gallery-2.webp" alt="" />
                            <h4>Beds</h4>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-sm-2 col-6 p-0">
                        <Link to={`/products/3?name=${encodeURIComponent('Dining Tables')}`} class="h-100">
                            <img class="img-fluid w-100" src="/assets/images/home-images/sec-5-gallery-3.webp" alt="" />
                            <h4>Dining Tables</h4>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-sm-2 col-6 p-0">
                        <Link to={`/products/7?name=${encodeURIComponent('Consoles')}`} class="h-100">
                            <img class="img-fluid w-100" src="/assets/images/home-images/sec-5-gallery-4.webp" alt="" />
                            <h4>Consoles</h4>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-sm-2 col-6 p-0">
                        <Link to={`/products/8?name=${encodeURIComponent('Side Tables')}`} class="h-100">
                            <img class="img-fluid w-100" src="/assets/images/home-images/sec-5-gallery-5.webp" alt="" />
                            <h4>Side Tables</h4>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-sm-2 col-6 p-0">
                        <Link to={`/products/6?name=${encodeURIComponent('Coffee Tables')}`} class="h-100">
                            <img class="img-fluid w-100" src="/assets/images/home-images/sec-5-gallery-6.webp" alt="" />
                            <h4>Coffee Tables</h4>
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
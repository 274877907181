import React from 'react';
import AppHeader from '../components/header';
import Footer from '../components/footer';
import Accordion from 'react-bootstrap/Accordion';

export default function FaqsPage() {
  return (
    <div>
        <AppHeader/>

        <div className="container-fluid hero_banner d-flex align-items-start justify-content-center" style={{backgroundImage:'url(./assets/images/about-banner.webp)'}}>
            <div className='container'>
                <div className="row d-flex align-items-center justify-content-center mt-5">
                    <div className="col-lg-6">
                        <h1 className="p-3 fw-bold text-center text-white mt-4 mb-0">
                            FAQs
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        
        <div className='container-fluid py-5' style={{backgroundColor:'#33333a'}}>
            <div className='container'>
                <div className='row d-flex align-items-center justify-content-center'>
                    <div className='col-lg-10'>
                        <div className='faq-accordions'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>1. What types of furniture do you offer?</Accordion.Header>
                                    <Accordion.Body>
                                    At VITTI Living, we provide a wide range of home furniture including living room sets, bedroom furniture, dining tables, chairs, wardrobes, and custom pieces tailored to your specific requirements. Our focus is on delivering premium quality and stylish designs to enhance any living space.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>2. Can I customize my furniture order?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, we specialize in customized furniture solutions. Our team of designers will work with you to understand your needs and preferences, helping you choose the right materials, finishes, and designs to create pieces that perfectly fit your home and style.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>3. What materials do you use in your furniture?</Accordion.Header>
                                    <Accordion.Body>
                                    We use a variety of high-quality materials including solid wood, engineered wood, metal, glass, and premium upholstery fabrics. Our commitment to quality ensures that all materials are durable, sustainable, and aesthetically pleasing.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>4. How long does it take to receive a custom order?</Accordion.Header>
                                    <Accordion.Body>
                                    The time required to complete a custom order varies depending on the complexity of the design and the specific materials chosen. Typically, it takes between 4 to 8 weeks from the time of order confirmation to delivery. Our team will provide you with an estimated timeline during the design consultation.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>5. Do you offer delivery and installation services?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, we provide delivery and installation services to ensure that your furniture arrives safely and is set up correctly in your home. Our professional team will handle all aspects of the delivery and installation process, giving you peace of mind and a hassle-free experience.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { getCategories } from "../utils/api";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export default function Footer() {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        fetchCategories();
    }, []);
    const fetchCategories = async () => {
        try {
            document.body.classList.add("loading-indicator");
            const response = await getCategories();
            if (response.status === 200) {
                setCategories(response.data.categories);
            }
            document.body.classList.remove("loading-indicator");
        } catch (e) {
            document.body.classList.remove("loading-indicator");
        }
    };
    return(
        <div className='footer'>
            <div className='container-fluid my-container'>
                <div className='row'>
                    <div className='col-lg-2 col-sm-6'>
                        <div className='footer_menu'>
                            <h2>Collection</h2>
                            <ul>
                                {categories.length > 0 ? (
                                    categories.map((category) => (
                                        <li key={category.id}>
                                            <NavLink to={`/products/${category.id}?name=${encodeURIComponent(category.name)}`} className="f-nav-link" >
                                                {category.name}
                                            </NavLink>
                                        </li>
                                    ))
                                ) : (
                                    <li><Link to='/products' className='f-nav-link'>Categories</Link></li>
                                )}
                                {/* <li><Link to='/products' className='f-nav-link'>Living</Link></li>
                                <li><Link to='/products' className='f-nav-link'>Dining</Link></li>
                                <li><Link to='/products' className='f-nav-link'>Bathroom</Link></li>
                                <li><Link to='/products' className='f-nav-link'>Kitchen</Link></li>
                                <li><Link to='/products' className='f-nav-link'>Wardrobe</Link></li>
                                <li><Link to='/products' className='f-nav-link'>Breakout</Link></li>
                                <li><Link to='/products' className='f-nav-link'>Bath</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-sm-6'>
                        <div className='footer_menu'>
                            <h2>Company</h2>
                            <ul>
                                <li><NavLink activeClassName='active' exact to='/aboutus' className='f-nav-link'>About Us</NavLink></li>
                                <li><NavLink activeClassName='active' exact to='/careers' className='f-nav-link'>Careers</NavLink></li>
                                <li><NavLink activeClassName='active' exact to='/contactus' className='f-nav-link'>Contact Us</NavLink></li>
                                <li><Link to='#' className='f-nav-link'>Catalogues</Link></li>
                                <li><NavLink activeClassName='active' exact to='/faqs' className='f-nav-link'>FAQ's</NavLink></li>
                                <li><NavLink activeClassName='active' exact to='/privacy_policy' className='f-nav-link'>Privacy Policy</NavLink></li>
                                <li><Link to='#' className='f-nav-link'>Terms & Conditions</Link></li>

                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                        <div className='footer_menu'>
                            <h2>Our Locations</h2>
                            <ul>
                                <li><a className='f-nav-adds' target='_blank' href='https://www.google.com/maps/place/VITTI+-+Contemporary+Furniture/@17.4172484,78.4016833,17z/data=!4m14!1m7!3m6!1s0x3bcb97221d65273d:0x721923570b8baaa2!2sVITTI+-+Contemporary+Furniture!8m2!3d17.4172484!4d78.4042582!16s%2Fg%2F11kqfg_g0g!3m5!1s0x3bcb97221d65273d:0x721923570b8baaa2!8m2!3d17.4172484!4d78.4042582!16s%2Fg%2F11kqfg_g0g?entry=ttu'>
                                    <i className='fa-solid fa-location'></i>
                                    <b>Jubilee Hills Showroom :</b> Vitti Contemporary Furniture, Plot no.310, Road no 78, Phase lll, Jubilee hills, Hyderabad, Telangana.</a>
                                </li>
                                <li><a href='#' className='f-nav-adds'><i className='fa-solid fa-location'></i> <b>Tellapur Showroom(Coming Soon) :</b> Vitti Contemporary Furniture, Sy no 450/2, Opposite My Home Tridasa, Tellapur, Ramachandrapuram, Hyderabad, Telangana, 502300</a></li>
                                <li><a href='#' className='f-nav-adds'><i className='fa-solid fa-map'></i> <b>Corporate Office :</b> Unit No 302, North Block, Mythri Villa, Plot No. 675, Road No. 13, Banjara Hills, Hyderabad 500034</a></li>
                                <li><a href='tel:919090494957' className='f-nav-adds'><i className='fa-solid fa-phone'></i>+91 9090 4949 57</a></li>
                                <li><a href='mailto:info@vittiliving.com' className='f-nav-adds'><i className='fa-solid fa-envelope'></i> info@vittiliving.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-sm-6 d-flex align-items-center justify-content-center'>
                        <div className='footer_social_icons'>
                            <p className='copyright'>© VITTI 2024. All Rights Reserved.</p>
                            <a className='facebook' href='#'><span className='fa-brands fa-facebook-f'></span></a>
                            <a className='instagram' href='https://www.instagram.com/vittiliving?igsh=MTg0ZWQ2eW05amg2bA==' target='_black'><span className='fa-brands fa-instagram'></span></a>
                            <a className='youtube' href='#'><span className='fa-brands fa-youtube'></span></a>
                            <a className='twitter' href='#'><span className='fa-brands fa-x-twitter'></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

export default function Popup_Enq_Form({ onClose }) {
    const form = useRef();
    const navigate = useNavigate();
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_number: '',
        message: '',
        category_name: '',
        sub_category_name: '',
        product_name: '',
    });

    // Fetch categories on component mount
    useEffect(() => {
        fetch('https://vittiliving.com/api/categories')
            .then(response => response.json())
            .then(data => setCategories(data.categories))
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    // Fetch subcategories when a category is selected
    useEffect(() => {
        if (formData.category_name) {
            const selectedCategory = categories.find(cat => cat.name === formData.category_name);
            if (selectedCategory) {
                setSubCategories(selectedCategory.sub_categories || []);
                setFormData(prevData => ({
                    ...prevData,
                    sub_category_name: '',
                    product_name: '',
                }));
                setProducts([]);
            }
        }
    }, [formData.category_name, categories]);

    // Fetch products when a subcategory is selected
    useEffect(() => {
        if (formData.sub_category_name) {
            const selectedSubCategory = subCategories.find(sub => sub.name === formData.sub_category_name);
            if (selectedSubCategory) {
                fetch(`https://vittiliving.com/api/products?sub_category_id=${selectedSubCategory.id}`)
                    .then(response => response.json())
                    .then(data => setProducts(data.products))
                    .catch(error => console.error('Error fetching products:', error));
            }
        }
    }, [formData.sub_category_name, subCategories]);

    const validateForm = () => {
        const errors = {};
        if (!formData.user_name.trim()) {
            errors.user_name = 'Name is required';
        }
        if (!formData.user_email.trim()) {
            errors.user_email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
            errors.user_email = 'Email is invalid';
        }
        if (!formData.user_number.trim()) {
            errors.user_number = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.user_number)) {
            errors.user_number = 'Phone number must be 10 digits';
        }
        if (!formData.category_name) {
            errors.category_name = 'Category is required';
        }
        if (!formData.sub_category_name) {
            errors.sub_category_name = 'Sub-category is required';
        }
        if (!formData.product_name) {
            errors.product_name = 'Product is required';
        }
        return errors;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            emailjs
                .sendForm('service_o8lgfqi', 'template_9npfzel', form.current, {
                    publicKey: 'bCyS6fsIMicPCRjvi',
                })
                .then(
                    () => {
                        setSubmissionStatus('success');
                        form.current.reset(); // Reset the form inputs
                        setFormData({ // Reset the formData state
                            user_name: '',
                            user_email: '',
                            user_number: '',
                            message: '',
                            category_name: '',
                            sub_category_name: '',
                            product_name: '',
                        });
                        console.log('SUCCESS!');
                        setTimeout(() => {
                            navigate('/thank-you');
                        }, 1000);
                    },
                    (error) => {
                        setSubmissionStatus('error');
                        console.log('FAILED...', error.text);
                    },
                );
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const modalRef = useRef();
    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    return (
        <div className='popup-enquiry-modal' ref={modalRef} onClick={closeModal}>
            <div className='enq-modal-pop'>
                <button onClick={onClose} className='btn float-end close-btn'><i className='fa-solid fa-close'></i></button>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="mb-3">
                        <label className="form-label">Name *</label>
                        <input 
                            type="text" 
                            name="user_name" 
                            className="form-control"
                            value={formData.user_name}
                            onChange={handleChange}
                        />
                        {formErrors.user_name && <div className="text-danger">{formErrors.user_name}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email *</label>
                        <input 
                            type="email" 
                            name="user_email" 
                            className="form-control"
                            value={formData.user_email}
                            onChange={handleChange}
                        />
                        {formErrors.user_email && <div className="text-danger">{formErrors.user_email}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Phone *</label>
                        <input 
                            type="text" 
                            name="user_number" 
                            className="form-control"
                            value={formData.user_number}
                            onChange={handleChange}
                        />
                        {formErrors.user_number && <div className="text-danger">{formErrors.user_number}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Category *</label>
                        <select 
                            name="category_name" 
                            className="form-control"
                            value={formData.category_name}
                            onChange={handleChange}
                        >
                            <option value="">Select a category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.name}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                        {formErrors.category_name && <div className="text-danger">{formErrors.category_name}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Sub-category *</label>
                        <select 
                            name="sub_category_name" 
                            className="form-control"
                            value={formData.sub_category_name}
                            onChange={handleChange}
                            disabled={!formData.category_name}
                        >
                            <option value="">Select a sub-category</option>
                            {subCategories.map(subCategory => (
                                <option key={subCategory.id} value={subCategory.name}>
                                    {subCategory.name}
                                </option>
                            ))}
                        </select>
                        {formErrors.sub_category_name && <div className="text-danger">{formErrors.sub_category_name}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Product *</label>
                        <select 
                            name="product_name" 
                            className="form-control"
                            value={formData.product_name}
                            onChange={handleChange}
                            disabled={!formData.sub_category_name}
                        >
                            <option value="">Select a product</option>
                            {products.map(product => (
                                <option key={product.id} value={product.name}>
                                    {product.name}
                                </option>
                            ))}
                        </select>
                        {formErrors.product_name && <div className="text-danger">{formErrors.product_name}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Message</label>
                        <textarea 
                            name="message" 
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                    <input type="submit" value="Send" className="btn btn-custom" />
                </form>
                {submissionStatus === 'success' && (
                    <div className="alert alert-success" role="alert">
                        Your message has been sent successfully!
                    </div>
                )}
                {submissionStatus === 'error' && (
                    <div className="alert alert-danger" role="alert">
                        There was an error sending your message. Please try again.
                    </div>
                )}
            </div>
        </div>
    );
}
